import * as Yup from 'yup';

const phoneRegExp = /^[6-9]\d{9}$/gi;
export const MerchantSchema = Yup.object({
  name: Yup.string().min(2).required('Please enter your name'),
  email: Yup.string().email().required('Please enter your email'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .required('Phone number is not valid'),
  message: Yup.string().required('Please tell us something about yourself'),
  describe_yourself: Yup.string().required('Please describe yourself briefly'),
  countries: Yup.string().required('Select one option from the list'),
});

export default { MerchantSchema };
