import React from 'react';
import Accordion from '../components/Accordion/Accordion';

export const describeOptions = [
  {
    label: 'I am Cashfree Merchant',
    value: 'merchant',
  },
  {
    label: 'Exploring Cashfree',
    value: 'explorer',
  },
];
const accountActivation = {
  title: (
    <div className="flex items-center">
      <span className="mr-[6px] flex-shrink-0 w-5md md:w-7md">
        <img
          src="/img/merchant-support/account-activation.svg"
          width="28"
          height="28"
          alt="Account activation"
        />
      </span>
      Account activation
    </div>
  ),
  data: [
    {
      key: 0,
      heading:
        'What are the documents needed for activating my Cashfree account?',
      content: (
        <>
          Once you sign-up, to activate your Cashfree merchant account you need
          to share business details along with scanned copies of the following
          business documents.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/63/what-the-documents-needed-for-activating-cashfree-account">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
    {
      key: 1,
      heading:
        'Do I need to make any changes to my website to make it complaint for account activation?',
      content: (
        <>
          When you create an account on Cashfree, our team does a business
          background check including your website and app audit and verifies the
          details shared by you. On being satisfied, we activate your Cashfree
          merchant account within 24-48 hours.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/65/need-make-changes-website-make-complaint-account-activation">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
    {
      key: 2,
      heading: 'How can I start using Cashfree?',
      content: (
        <>
          To get started with{' '}
          <a
            href="https://cashfree.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cf-green underline hover:underline">
            Cashfree
          </a>
          , visit the{' '}
          <a
            href="https://merchant.cashfree.com/merchant/sign-up/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cf-green underline hover:underline">
            Cashfree website
          </a>
          , click on Create account, and complete your profile. You can learn
          more about our account creation process{' '}
          <a
            href="https://dev.cashfree.com/get-started/merchant-account#sign-up"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cf-green underline hover:underline">
            here
          </a>
          .{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/57/how-can-i-start-using-cashfree">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
  ],
};
const pricing = {
  title: (
    <div className="flex items-center">
      <span className="mr-[6px] flex-shrink-0 w-5md md:w-7md">
        <img
          src="/img/merchant-support/pricing.svg"
          width="28"
          height="28"
          alt="Pricing"
        />
      </span>
      Pricing
    </div>
  ),
  data: [
    {
      key: 0,
      heading: 'What charges do I need to pay for using Cashfree products?',
      content: (
        <>
          You can check out all Cashfree products and their pricing here. You
          can also talk to our payments expert directly and know more about the
          product and pricing by submitting this{' '}
          <a
            href="https://www.cashfree.com/contact-sales"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cf-green underline hover:underline">
            form
          </a>
          .
        </>
      ),
    },
    {
      key: 1,
      heading:
        'Do you provide billing Invoices for transactions and services used?',
      content: (
        <>
          For all the transactions processed, an invoice is raised and shows up
          on your Cashfree Merchant Dashboard on/after the 5th of the next
          month. You will be notified of the invoice generation on your
          registered email address provided to us.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/353/you-provide-billing-invoices-transactions-and-services-used">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
    {
      key: 2,
      heading: 'What is TDR?',
      content: (
        <>
          Transaction Discount Rate ( TDR ) is the fee that you pay for every
          transaction processed using a payment gateway. The rates may differ
          based on the payment mode used by the customer.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/354/what-is-tdr">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
  ],
};
const generalQueries = {
  title: (
    <div className="flex items-center">
      <span className="mr-[6px] flex-shrink-0 w-5md md:w-7md">
        <img
          src="/img/merchant-support/general-queries.svg"
          width="28"
          height="28"
          alt="Pricing"
        />
      </span>
      General queries
    </div>
  ),
  data: [
    {
      key: 0,
      heading: 'How to integrate Paytm with Cashfree Payment Gateway?',
      content: (
        <>
          To show Paytm as a payment option on your checkout page, you need to
          create a Paytm business account and then connect Paytm with Cashfree.{' '}
          <a
            href="https://www.cashfree.com/help/364/how-to-integrate-paytm-with-cashfree-payment-gateway"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cf-green underline hover:underline">
            Read more
          </a>
        </>
      ),
    },
    {
      key: 1,
      heading:
        'Can I accept international payments using Cashfree Payment Gateway?',
      content: (
        <>
          Yes, with Cashfree Payment Gateway you can accept international
          payments using a wide range of options.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/362/accept-international-payments-using-cashfree-payment-gateway">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
    {
      key: 2,
      heading: 'Is Cashfree PCI DSS compliant?',
      content: (
        <>
          The Payment Card Industry Data Security Standard (PCI DSS) is an
          information security standard for organizations that handle branded
          credit cards from major card schemes.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/109/is-cashfree-pci-dss-compliant">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
  ],
};
const productQueries = {
  title: (
    <div className="flex items-center">
      <span className="mr-[6px] flex-shrink-0 w-5md md:w-7md">
        <img
          src="/img/merchant-support/product-queries.svg"
          width="28"
          height="28"
          alt="Product queries"
        />
      </span>
      Product queries
    </div>
  ),
  data: [
    {
      key: 0,
      heading:
        'Can I receive payments from my customers without integrating Cashfree to my website?',
      content: (
        <>
          You can use Cashfree Payment Gateway Dashboard to create orders and
          accept payments using{' '}
          <a
            href="https://docs.cashfree.com/docs/payment-gateway-dashboard#create-payment-link"
            target="_blank"
            rel="noopener noreferrer"
            className="text-cf-green underline hover:underline">
            payment link
          </a>
          .
        </>
      ),
    },
    {
      key: 1,
      heading:
        'When will the payment gateway charges be deducted for my transactions?',
      content:
        'For every transaction that your customers make through Cashfree, TDR will be deducted from the total amount and the remaining amount will be settled to your bank account.',
    },
    {
      key: 2,
      heading: 'Can I create Payment Links in bulk?',
      content:
        'Yes, you can send Payment Links in bulk with our Batch feature. You can upload a XLSX or CSV file containing the list of customers’ contact details, amount and purpose for the links and send the requests in bulk.',
    },
    {
      key: 3,
      heading: 'What is Auto Collect?',
      content: (
        <>
          Cashfree&apos;s Auto-Collect solution allows you to accept and manage
          payments automatically. Create any number of Virtual Account and
          receive and reconcile inward NEFT, RTGS, IMPS & UPI payments
          automatically.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/282/what-is-auto-collect">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
    {
      key: 4,
      heading: 'What is Easy Split?',
      content: (
        <>
          Easy Split by Cashfree Payments is a split payment gateway for
          marketplaces that helps marketplaces accept payments from customers,
          deduct commissions and automatically settle payments to the vendors.{' '}
          <a
            className="text-cf-green underline hover:underline"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.cashfree.com/help/410/what-exactly-is-easy-split">
            Read more <span className="chevron" />
          </a>
        </>
      ),
    },
    {
      key: 5,
      heading: 'What is a Cashgram?',
      content:
        "Cashgram is an instant payout link used by businesses to send money to their customers, vendors, or partners when only their email address or phone number is known. Once the customer redeems the Cashgram, the money will be deducted from your account and transferred to the recipient's account.",
    },
  ],
};

// const popularData = [
//   {
//     key: 0,
//     heading: 'What is the UPI transaction limit?',
//     content:
//       'The UPI transaction limit is Rs. 1 lakh per transaction, as set by NPCI. The UPI transaction limit per day varies from bank to bank since NPCI has not issued any regulation.',
//   },
//   {
//     key: 1,
//     heading: 'What is UPI Payment?',
//     content:
//       'The UPI transaction limit is Rs. 1 lakh per transaction, as set by NPCI. The UPI transaction limit per day varies from bank to bank since NPCI has not issued any regulation.',
//   },
//   {
//     key: 2,
//     heading: 'What is Cashfree UPI Payment Gateway?',
//     content:
//       'The UPI transaction limit is Rs. 1 lakh per transaction, as set by NPCI. The UPI transaction limit per day varies from bank to bank since NPCI has not issued any regulation.',
//   },
// ];

export const faqData = [
  {
    key: 0,
    heading: accountActivation.title,
    content: (
      <Accordion className="child" data={accountActivation.data} arrow="plus" />
    ),
  },
  {
    key: 1,
    heading: pricing.title,

    content: <Accordion className="child" data={pricing.data} arrow="plus" />,
  },
  {
    key: 2,
    heading: generalQueries.title,

    content: (
      <Accordion className="child" data={generalQueries.data} arrow="plus" />
    ),
  },
  {
    key: 3,
    heading: productQueries.title,
    content: (
      <Accordion className="child" data={productQueries.data} arrow="plus" />
    ),
  },
];

export const stats = [
  {
    id: 0,
    countUpProps: {
      value: 300000,
      suffix: '+',
      useIndianFormat: true,
    },
    desc: 'Merchants',
  },
  {
    id: 1,
    countUpProps: {
      value: 40,
      prefix: 'USD ',
      suffix: 'B+',
    },

    desc: 'Processed annually',
  },
  {
    id: 2,
    countUpProps: {
      value: 200,
      suffix: 'M+',
    },
    desc: 'Bank accounts served',
  },
];
export const heroLogoData = [
  {
    id: 0,
    src: 'nykaa.png',
    width: '181',
    height: '60',
    className: 'max-w-[60px]',
    alt: 'Nykaa',
  },
  {
    id: 1,
    src: 'livspace.png',
    width: '319',
    height: '69',
    className: 'max-w-[106px]',
    alt: 'Livspace',
  },
  {
    id: 2,
    src: 'hdfc.png',
    width: '301',
    height: '51',
    className: 'max-w-[100px]',
    alt: 'HDFC Bank',
  },
  {
    id: 3,
    src: 'ixigo.png',
    width: '195',
    height: '95',
    className: 'max-w-[64px]',
    alt: 'ixigo',
  },
  {
    id: 4,
    src: 'bajaj-finserv.png',
    width: '193',
    height: '53',
    className: 'max-w-[64px]',
    alt: 'bajaj finserv',
  },
  {
    id: 5,
    src: 'l&t.png',
    width: '331',
    height: '60',
    className: 'max-w-[110px]',
    alt: 'L&T Financial Services',
  },
  {
    id: 6,
    src: 'dream11.png',
    width: '211',
    height: '47',
    className: 'max-w-[70px]',
    alt: 'dream11',
  },
  {
    id: 7,
    src: 'bigbasket.png',
    width: '328',
    height: '98',
    className: 'max-w-[109px]',
    alt: 'bigbasket',
  },
];
export const heroLogoSettings = {
  dots: false,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 0,
  pauseOnHover: false,
  draggable: false,
  centerMode: true,
  speed: 2000,
  variableWidth: true,
  rtl: true,
  cssEase: 'linear',
};
export const faqTabsData = [
  {
    key: 0,
    heading: accountActivation.title,
    content: (
      <Accordion
        className="child"
        data={accountActivation.data}
        arrow="plus"
        border
      />
    ),
  },
  {
    key: 1,
    heading: pricing.title,

    content: (
      <Accordion className="child" data={pricing.data} arrow="plus" border />
    ),
  },
  {
    key: 2,
    heading: generalQueries.title,
    content: (
      <Accordion
        className="child"
        data={generalQueries.data}
        arrow="plus"
        border
      />
    ),
  },
  {
    key: 3,
    heading: productQueries.title,
    content: (
      <Accordion
        className="child"
        data={productQueries.data}
        arrow="plus"
        border
      />
    ),
  },
];
