import React from 'react';
import Headroom from 'react-headroom';
import { Link } from 'gatsby';

import './Header.scss';

const LogoHeader = () => {
  return (
    <>
      <nav className="nav nav-only-logo">
        <Headroom disableInlineStyles>
          <Link to="/" className="logo lg:max-w-[128.45px]">
            <img
              src="/img/cashfree-logo.svg"
              className="dark-logo"
              width="150px"
              height="auto"
              alt="cashfree"
            />

            <img
              src="/img/cashfree-logo-white.svg"
              className="white-logo"
              width="150px"
              height="auto"
              alt="cashfree"
            />
          </Link>
          <div className="row justify-space-between menu" />
        </Headroom>
      </nav>

      <div className="overlay-nav" />
    </>
  );
};

export default LogoHeader;
